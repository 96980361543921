import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion';

const Notification = ({ message }) => {

    const [position, setPosition] = useState('10px');
    const [show, setShow] = useState('block');

    useEffect(() => {
        setTimeout(() => {
            setPosition('-330px');
        }, 10000);

        setTimeout(() => {
            setShow('none');
        }, 11000);
    }, []);

    return (
        <motion.div
            initial={{ right: '-330px' }}
            animate={{ right: position }}
            style={{ color: 'red', display: show, textAlign: 'center' }}
            transition={{ duration: 1 }}
            className='notification'
        >
            <div className='notification-content'>{message}</div>
        </motion.div>
    )
}

export default Notification;